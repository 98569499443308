<template>
  <fw-layout full mobile-ready back-to="/">
    <template #main-content>
      <PdfViewer v-if="file" :file="file" />
    </template>
  </fw-layout>
</template>

<script>
import PdfViewer from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelPDFviewer'
export default {
  components: {
    PdfViewer
  },
  data() {
    return {
      file: null
    }
  },
  mounted() {
    this.file = this.$route.query.file
    let key = this.$route.params.key
    let token = this.$route.params.token
    let filename = this.$route.params.filename
    let url_format = window.localStorage.getItem('file_url_format')
    if (key && token && filename && url_format) {
      this.file = url_format
        .replace('{key}', key)
        .replace('{token}', token)
        .replace('{filename}', filename)
    }
  }
}
</script>
